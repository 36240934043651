<template>
<b-overlay :show="loading" class="m-1">
  <div class="mb-3">
    <b-row class="mt-2">
      <b-col cols="12" md="10">
        <b-input-group class="input-group-merge">
          <b-form-input v-model="searchFilter" size="lg" placeholder="Search" class="search-product" />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" class="text-muted" />
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col cols="12" md="2">
        <div class="d-flex align-items-center justify-content-end">
          <b-button variant="primary" size="lg" :to="{ name: 'admin-new-faq' }">
            <span class="text-nowrap" style="font-weight: 600; font-size: 14px; line-height: 160%; color: #FFFFFF;">
              Add New FAQ
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
  <list-content 
    v-if="filteredFaqs.length > 0"
    :items="filteredFaqs"
    :current-page.sync="currentPage"
    :total-records="totalRecords"
    :per-page="perPage"
    :detail-page="'admin-single-faq'"
    :show-badge="true"
    :delete-title="'Delete FAQ?'"
    :delete-message="'You are about to delete this FAQ. Continue?'"
    @delete-item="onDeleteFaq"
  />

  <div v-else-if="!loading">
    <h3 class="my-5">
      No content available.
    </h3>
  </div>

  <div></div>
</b-overlay>
</template>

<script>
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import ListContent from "@/@core/components/shared/Miscellaneous/ListContent.vue"
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BPagination,
  BRow,
  BBadge,
} from "bootstrap-vue";
import {
  get
} from "lodash";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  kFormatter
} from "@core/utils/filter";
import { formatDate } from "@/@core/utils/utils"

const watchHandler = {
  handler() {
    this.fetchAllData()
  },
  immediate: false
}

export default {
  components: {
    ContentWithSidebar,
    BCard,
    BRow,
    BOverlay,
    BLink,
    BCol,
    BCardText,
    BCardBody,
    BCardTitle,
    BFormInput,
    BButton,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    ListContent
  },
  data() {
    return {
      loading: false,
      searchFilter: '',
      faqs: [],
      currentPage: 1,
      perPage: 50,
      totalRecords: 0,
      rows: 2,

    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.loans.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    },
    filteredFaqs() {
      return this.faqs.filter(this.canView)
    }
  },
  watch: {
    searchFilter: watchHandler,
    perPage: watchHandler,
    currentPage: watchHandler,
    statusFilter: watchHandler,
    dateStart: watchHandler,
    dateEnd: watchHandler,
  },
  created() {
    this.fetchAllData();
  },
  methods: {
    formatDate,
    kFormatter,
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.searchFilter,
          status: this.statusFilter,
          from: this.dateStart,
          to: this.dateEnd
        }

        const request = await this.useJwt().adminService.fetchFaqs(query);
        const {
          data,
          pagination
        } = request.data;
        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;

        this.faqs = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onDeleteFaq(faq_id){
      try {
        this.loading = true;
        await this.useJwt().adminService.deleteFaq(faq_id);

        this.faqs = this.faqs.filter(faq => faq._id !== faq_id);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Faq deleted successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>

<style lang="css" >

</style>
